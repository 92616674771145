import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70')
];

export const server_loads = [];

export const dictionary = {
		"/(facility-profile)/(dashboard)": [17,[3]],
		"/404": [48],
		"/(facility-profile)/(dashboard)/account": [18,[3]],
		"/(auth)/account/change-password": [8,[2]],
		"/(auth)/account/confirm/[email]/[pid]": [9,[2]],
		"/(facility-profile)/(dashboard)/account/favorites": [19,[3]],
		"/(facility-profile)/(dashboard)/account/lists": [20,[3]],
		"/(auth)/account/password-reset": [10,[2]],
		"/(auth)/account/request-password-reset-sent": [12,[2]],
		"/(auth)/account/request-password-reset": [11,[2]],
		"/(auth)/account/reset-password": [13,[2]],
		"/(facility-profile)/(dashboard)/account/reviews": [21,[3]],
		"/(facility-profile)/claim-facility": [32,[3]],
		"/(facility-profile)/(dashboard)/company": [22,[3]],
		"/(facility-profile)/(dashboard)/company/d411-id-questions/[id]": [23,[3]],
		"/(facility-profile)/(dashboard)/company/reviews/[id]": [24,[3]],
		"/(facility-profile)/(dashboard)/company/suggestions/[id]": [25,[3]],
		"/(facility-profile)/(dashboard)/company/users/invite": [27,[3]],
		"/(facility-profile)/(dashboard)/company/users/[id]": [26,[3]],
		"/(facility-profile)/d411id/review/add": [40,[3]],
		"/(facility-profile)/d411id/[d411id]": [33,[3]],
		"/(facility-profile)/d411id/[d411id]/edit": [34,[3]],
		"/(facility-profile)/d411id/[d411id]/edit/related": [35,[3]],
		"/(facility-profile)/d411id/[d411id]/edit/thank-you": [36,[3]],
		"/(facility-profile)/d411id/[d411id]/gallery": [37,[3]],
		"/(facility-profile)/d411id/[d411id]/reviews": [38,[3]],
		"/(facility-profile)/d411id/[d411id]/reviews/add": [39,[3]],
		"/(facility-profile)/lists/[id]": [41,[3]],
		"/(facility-profile)/(dashboard)/map-form": [28,[3]],
		"/(facility-profile)/offer/[offer_id]": [42,[3]],
		"/(facility-profile)/offer/[offer_id]/payment": [43,[3]],
		"/(facility-profile)/offer/[offer_id]/profile": [44,[3]],
		"/(facility-profile)/offer/[offer_id]/profile/[facility_serial]": [45,[3]],
		"/(facility-profile)/offer/[offer_id]/thank-you": [46,[3]],
		"/(facility-profile)/(dashboard)/request-d411id": [29,[3]],
		"/(facility-profile)/(dashboard)/request-d411id/thank-you": [30,[3]],
		"/(auth)/sign-in": [14,[2]],
		"/(auth)/sign-up": [15,[2]],
		"/(auth)/sign-up/thank-you": [16,[2]],
		"/to-remove/(simple)": [55,[7]],
		"/to-remove/(simple)/about": [56,[7]],
		"/to-remove/(simple)/app": [57,[7]],
		"/to-remove/(sidebar)/brokers": [51,[6]],
		"/to-remove/(sidebar)/carriers": [52,[6]],
		"/to-remove/(simple)/claim-your-facility-its-in-the-queue": [58,[7]],
		"/to-remove/(simple)/company": [59,[7]],
		"/to-remove/(simple)/contact": [60,[7]],
		"/to-remove/(simple)/dock-details": [61,[7]],
		"/to-remove/(simple)/dock411-facility-profiles": [62,[7]],
		"/to-remove/(sidebar)/drivers": [53,[6]],
		"/to-remove/(simple)/facility-form-received": [63,[7]],
		"/to-remove/(sidebar)/insurance-companies": [54,[6]],
		"/to-remove/(simple)/lets-roll": [64,[7]],
		"/to-remove/(simple)/opendock-and-dock411-partnering-for-you": [65,[7]],
		"/to-remove/(simple)/privacy-policy": [66,[7]],
		"/to-remove/(simple)/shippers": [67,[7]],
		"/to-remove/(simple)/strategic-shipper-program": [68,[7]],
		"/to-remove/(simple)/terms-and-conditions": [69,[7]],
		"/to-remove/(simple)/the-411": [70,[7]],
		"/to-remove/(blog-article)/the-411/[code]": [49,[4]],
		"/to-remove/(multi-hero)/users": [50,[5]],
		"/(facility-profile)/(dashboard)/want-to-know": [31,[3]],
		"/(facility-profile)/welcome": [47,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';